import React, { useEffect } from "react";
import Slider from "react-slick";
import { BookingForm } from "../components/BookingForm";
import { Link } from "react-router-dom";
import Fancybox from "../components/fancybox-library/fancybox";
import { Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeCruises } from "./../data/cruises-slice";
import Loader from "../Loader";
import { Helmet } from "react-helmet-async";

import { Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";
function FacilitiesBox({ src, title, classs }) {
  return (
    <div className="col-lg-2 col-md-4 col-sm-6 px-0 facility">
      <div>
        {" "}
        <div className={classs}>
          <div className="feature-icon">
            <img src={src} alt="" />
          </div>
          <div className="feature-content">
            <h4>{title}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
const Cruises = () => {
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.cruises);
  let hotelUrl = "";
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    hotelUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    hotelUrl = "nile-capital-cruise";
  }
  useEffect(() => {
    dispatch(fetchHomeCruises({ language, hotelUrl }));
  }, [dispatch, language, hotelUrl]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  const settingsCabins = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsSlider = {
    dots: false,
    arrows: false,
    nextArrow: (
      <div className="owl-next curser-pointer">
        <i className="bi bi-chevron-right"></i>
      </div>
    ),
    prevArrow: (
      <div className="owl-prev curser-pointer">
        <i className="bi bi-chevron-left"></i>
      </div>
    ),
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const settingsAbout = {
    dots: true,
    arrows: false,
    centerMode: true, // Enables centered view
    centerPadding: "0px",
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => (
      <div className="owl-dot">
        <span></span>
      </div>
    ),
    dotsClass: "owl-dots", // Custom class name for dots container
  };

  const facilitiesCruises = [
    {
      src: "assets/images/home-1/feature-1.png",
      title: "Luxury Cabins",
      classs: "single-feature-box f",
    },
    {
      src: "https://cms.nilecapitalcruises.com/images/uploads/facilities-icon2.png",
      title: "Sundeck",
      classs: "single-feature-box ff",
    },
    {
      src: "https://cms.nilecapitalcruises.com/images/uploads/facilities-icon-4.png",
      title: "SPA",
      classs: "single-feature-box fff",
    },
    {
      src: "assets/images/home-1/feature-4.png",
      title: "Gastronomy",
      classs: "single-feature-box ffff",
    },
    {
      src: "assets/images/home-1/feature-5.png",
      title: "Swimming Pool",
      classs: "single-feature-box fffff",
    },
  ];
  const facilitiesAgatha = [
    {
      src: "assets/images/home-1/feature-1.png",
      title: "Luxury Cabins",
      classs: "single-feature-box a",
    },
    {
      src: "https://cms.nilecapitalcruises.com/images/uploads/facilities-icon2.png",
      title: "Sundeck",
      classs: "single-feature-box aa",
    },
    {
      src: "https://cms.nilecapitalcruises.com/images/uploads/facilities-icon-4.png",
      title: "Jacuzzi",
      classs: "single-feature-box aaa",
    },
    {
      src: "assets/images/home-1/feature-4.png",
      title: "Gastronomy",
      classs: "single-feature-box aaaa",
    },
    {
      src: "https://cms.nilecapitalcruises.com/images/uploads/facilities-icon15.png",
      title: "Arabic Seating Area",
      classs: "single-feature-box aaaaa",
    },
  ];
  const settingsRooms = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 997,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
    arrows: true,
    nextArrow: (
      <div className="owl-nav">
        <div className="owl-next">
          <i className="bi bi-arrow-right"></i>
        </div>
      </div>
    ),
    prevArrow: (
      <div className="owl-nav">
        <div className="owl-prev">
          <i className="bi bi-arrow-left"></i>
        </div>
      </div>
    ),
  };

  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <meta
                  name="description"
                  content={data.metatagDecription ? data.metatagDecription : ""}
                />
                <meta
                  name="keywords"
                  content={
                    data.metatagTitle
                      ? data.metatagTitle
                      : data.intro_WelcomeTitle
                  }
                />
                <title>
                  {data.pageTitle ? data.pageTitle : data.intro_WelcomeTitle}
                </title>
              </Helmet>
              {/*Slider */}
              <section>
                <div className="hero-slider">
                  <Slider {...settingsSlider}>
                    {data.list_SlidersCruises?.map((slider, index) => (
                      <Box
                        key={index}
                        className="hero-area   align-items-center d-flex"
                        sx={{
                          background: `url(${
                            isMobile
                              ? slider.sliderPhotoMobile
                              : isTablet
                              ? slider.sliderPhotoTablet
                              : slider.sliderPhoto
                          })`,
                        }}
                      >
                        <div className="container">
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <Zoom duration="1000" cascade delay="0">
                                <div className="hotel-rating">
                                  <ul>
                                    <li>
                                      <i className="bi bi-star-fill"></i>
                                    </li>
                                    <li>
                                      <i className="bi bi-star-fill"></i>
                                    </li>
                                    <li>
                                      <i className="bi bi-star-fill"></i>
                                    </li>
                                    <li>
                                      <i className="bi bi-star-fill"></i>
                                    </li>
                                    <li>
                                      <i className="bi bi-star-fill"></i>
                                    </li>
                                  </ul>
                                </div>
                                <div className="hero-content">
                                  <h4>
                                    {language === "en"
                                      ? "Welcome to"
                                      : " أهلا بك في"}{" "}
                                  </h4>
                                  <h1>{data.intro_WelcomeTitle}</h1>
                                </div>
                                {/*<div className="luxury-button">
                                  <Link to="/">Discover More </Link>
                                </div> */}
                              </Zoom>
                              <div className="hero-contact">
                                <Link to="tel:+20 111 111 8733">
                                  <i className="bi bi-telephone-fill"></i>+20
                                  111 111 8733
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Box>
                    ))}
                  </Slider>
                </div>
              </section>

              {window.location.href.includes("agatha") ? "" : <BookingForm />}
              {/*About*/}
              <div className="about-area mt-5 ">
                <div className="container">
                  <Zoom duration="3500" delay="0" cascade triggerOnce>
                    <div className="row align-items-center  ">
                      <div className="col-lg-6 col-md-12">
                        <div className="row">
                          {/* Implement the carousel using a React package or your own solution */}
                          <div className="about_list  ">
                            <Slider {...settingsAbout}>
                              <div>
                                <img src={data.introPhoto1} alt="" />
                              </div>
                              <div>
                                <img src={data.introPhoto2} alt="" />
                              </div>
                              {/* Add more slides as needed */}
                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 upper">
                        <div className="section-title two">
                          <h4>
                            {language === "en" ? "Welcome to" : " أهلا بك في"}{" "}
                          </h4>
                          <h1>{data.intro_WelcomeTitle}</h1>

                          <p
                            className="section-desc-2"
                            dangerouslySetInnerHTML={{
                              __html: `${data.intro_WelcomeText}`,
                            }}
                          />
                        </div>
                        <div className="animation-bar"></div>
                        {/*<div className="luxury-button">
                          <a href="#.">About More </a>
                        </div> */}
                      </div>
                    </div>
                  </Zoom>
                </div>
              </div>
              {/*Cabins*/}
              <section id="cabins">
                <div className="room-title-area">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <Zoom duration="3000" delay="0" triggerOnce>
                          <div className="section-title center">
                            {" "}
                            <div className="section-thumb">
                              <img
                                src="assets/images/home-1/section-shape1.png"
                                alt=""
                              />
                            </div>
                            <h1>
                              {language === "en"
                                ? "Cabins & Suites"
                                : " الكبائن والأجنحة "}
                            </h1>
                            <p className="section-desc-1">
                              {data.accommodationText}
                            </p>
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="room-area">
                  <div className="container">
                    <Zoom duration="3000" delay="0">
                      <div className="row margin-top">
                        <Slider {...settingsCabins}>
                          {data.list_HomeRooms?.map((room, index) => (
                            <div key={index} className="col-lg-6 col-md-6">
                              <div className="room-single-box">
                                <div className="room-thumb">
                                  <img
                                    src={room.roomPhoto}
                                    alt={room.roomName}
                                    loading="lazy"
                                  />
                                  <div className="room-details-button">
                                    <Link
                                      to={`/${hotelUrl}/accommodation/${room.roomURL}`}
                                    >
                                      {language === "en"
                                        ? "View Details"
                                        : "عرض التفاصيل"}

                                      <i className="bi bi-arrow-right"></i>
                                    </Link>
                                  </div>
                                </div>
                                {/* <div className="room-pricing">
                              <span className="dolar">{room.price} </span>
                              <span>Night </span>
                            </div> */}
                                <div className="room-content">
                                  <h4>{room.roomTypeCategoryName}</h4>
                                  <Link
                                    to={`/${hotelUrl}/accommodation/${room.roomURL}`}
                                  >
                                    {room.roomName}
                                  </Link>

                                  <div className="d-flex align-items-center">
                                    <p>{room.roomSize}</p>
                                    {room.maxOccupancy ? (
                                      <p className="px-3">|</p>
                                    ) : (
                                      ""
                                    )}

                                    <p>{room.maxOccupancy}</p>
                                  </div>
                                </div>
                                <div
                                  className="room-bottom"
                                  dir={`${language === "en" ? "ltr" : "rtl"}`}
                                >
                                  <div className="room-bottom-icon">
                                    {room.roomBed && (
                                      <span>
                                        <img
                                          src="assets/images/home-1/room-bottom-icon.png"
                                          alt=""
                                          loading="lazy"
                                          className={`${
                                            language === "ar" && "ms-2 ms-2"
                                          }`}
                                        />
                                        {room.roomBed}
                                      </span>
                                    )}
                                  </div>

                                  <div className="coustomar-rating">
                                    <ul>
                                      <li>
                                        <i className="bi bi-star-fill"></i>
                                      </li>
                                      <li>
                                        <i className="bi bi-star-fill"></i>
                                      </li>
                                      <li>
                                        <i className="bi bi-star-fill"></i>
                                      </li>
                                      <li>
                                        <i className="bi bi-star-fill"></i>
                                      </li>
                                      <li>
                                        <i className="bi bi-star-fill"></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </Zoom>
                  </div>
                </div>
              </section>
              {/*Facilities*/}
              <div className="feature-area">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Zoom duration="1500" delay="0">
                        <div className="section-title center" data-cue="zoomIn">
                          <div className="section-thumb">
                            <img
                              src="assets/images/home-1/section-shape1.png"
                              alt=""
                            />
                          </div>

                          <h1>
                            {language === "en"
                              ? "Leisure Facilities"
                              : "الأنشطة والترفية"}
                          </h1>
                          <p className="section-desc-1">
                            {data.facilitiesText}
                          </p>
                        </div>
                      </Zoom>
                    </div>
                  </div>
                  <Zoom duration="2500" delay="0">
                    <div
                      className="row d-flex justify-content-between"
                      data-cue="zoomIn"
                    >
                      {hotelUrl === "nile-capital-cruise" ? (
                        <>
                          {facilitiesCruises.map((facilities, index) => (
                            <FacilitiesBox
                              classs={facilities.classs}
                              key={index}
                              src={facilities.src}
                              title={facilities.title}
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          {facilitiesAgatha.map((facilities, index) => (
                            <FacilitiesBox
                              classs={facilities.classs}
                              key={index}
                              src={facilities.src}
                              title={facilities.title}
                            />
                          ))}
                        </>
                      )}
                    </div>
                  </Zoom>
                </div>
              </div>
              {/*itinearares*/}
              <section>
                <div className="room-area home-three" id="rooms">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <Zoom duration="2000" delay="0" cascade>
                          <div className="section-title">
                            <h4>{data.itinerariesTitleTop}</h4>
                            <h2>{data.itinerariesTitle}</h2>

                            <p className="section-desc-1">
                              {data.itinerariesText}
                            </p>
                          </div>
                        </Zoom>
                      </div>
                      <div className="col-12 col-lg-8">
                        <Zoom duration="3500" delay="0" cascade>
                          <div className="row">
                            <Slider {...settingsRooms}>
                              {data.list_ItinerariesCruises.map(
                                (room, index) => (
                                  <div key={index} className="col-lg-12">
                                    <div className="single-room-box">
                                      <div className="room-thumb">
                                        <img
                                          src={room.itineraryPhoto}
                                          alt={room.name}
                                          style={{ height: "280px" }}
                                        />
                                        {/*<div className="room-quality">
                            <span>
                              <i className="bi bi-people-fill"></i>Adults:{" "}
                              {room.adults}{" "}
                            </span>
                            <span>
                              <i className="bi bi-aspect-ratio"></i>Size:{" "}
                              {room.size}
                            </span>
                          </div> */}
                                      </div>
                                      <div className="room-content">
                                        <Link
                                          to={`/${hotelUrl}/itineraries/${room.itineraryURL}`}
                                        >
                                          {room.itineraryName}
                                        </Link>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: `${room.itineraryIntro}`,
                                          }}
                                        ></p>
                                      </div>
                                      <div className="room-bottom">
                                        <div className="luxury-button">
                                          <Link
                                            to={`/${hotelUrl}/itineraries/${room.itineraryURL}`}
                                          >
                                            {language === "en"
                                              ? "Book Now"
                                              : "احجز الأن"}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </Slider>
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*Hero*/}
              <section>
                {" "}
                <div
                  className="video-area home-five"
                  style={{
                    background: `url(${data.bannerCruise})`,
                    backgroundRepeat: " no-repeat",
                    backgroundSize: "100% 100%",
                    backgroundPosition: " center center",
                    backgroundAttachment: "fixed",
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <Zoom duration="2000" delay="0" cascade>
                        <div
                          className="col-md-12 text-center"
                          data-cue="zoomIn"
                          data-show="true"
                          style={{
                            animationName: "zoomIn",
                            animationDuration: "2500ms",
                            animationTimingFunction: "ease",
                            animationDelay: "0ms",
                            animationDirection: "normal",
                            animationFillMode: "both",
                          }}
                        >
                          <div className="section-title center">
                            <img
                              src="assets/images/home5/section-sahpe.png"
                              alt=""
                            />
                            <h1>{data.intro_WelcomeTitle}</h1>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                            className="vedio-content"
                            data-cue="zoomIn"
                            data-show="true"
                            style={{
                              animationName: "zoomIn",
                              animationDuration: "2500ms",
                              animationTimingFunction: "ease",
                              animationDelay: "0ms",
                              animationDirection: "normal",
                              animationFillMode: "both",
                            }}
                          >
                            <Fancybox
                              options={{
                                Carousel: {
                                  infinite: false,
                                },
                              }}
                            >
                              <a
                                data-autoplay="true"
                                className="video-vemo-icon venobox vbox-item"
                                // href={data.bannerCruiseVideoURL}
                                href={`${data.bannerCruiseVideoURL}`}
                                data-fancybox="youtube"
                              >
                                <i className="bi bi-play-fill"></i>
                              </a>
                            </Fancybox>
                          </div>
                        </div>
                      </Zoom>
                    </div>
                  </div>
                </div>
              </section>
              {/*services*/}
              <div className="service-area home-five">
                <div className="container">
                  <Zoom duration="2000" delay="0">
                    <div className="row">
                      <div className="col-12 col-md-12 text-center">
                        <Zoom duration="1500" delay="0">
                          <div
                            className="section-title center"
                            data-cue="zoomIn"
                          >
                            <div className="section-thumb">
                              <img
                                src="assets/images/home-1/section-shape1.png"
                                alt=""
                              />
                            </div>
                            <h1 className="text-dark mb-3">
                              {language === "en" ? "Services" : "الخدمات"}{" "}
                            </h1>
                            <p className="section-desc-1 px-lg-5 mb-5 mb-lg-0">
                              {data.servicesText}
                            </p>
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  </Zoom>

                  <div className="row  ">
                    <Zoom
                      className="col-12 col-md-12 col-lg-4 "
                      duration="3500"
                      delay="0"
                    >
                      <div className="single-service-box" data-cue="zoomIn">
                        <div className="service-box-thumb">
                          <img src={data.photoServices1} alt="" />
                        </div>
                        <div className="service-content">
                          <h4>{data.servicesBox1_Title}</h4>
                          <p>{data.servicesBox1_Text}</p>
                        </div>
                      </div>
                    </Zoom>
                    {/* Service 02 */}
                    <Zoom
                      className="col-12 col-md-12 col-lg-4"
                      duration="3500"
                      delay="0"
                    >
                      <div className="single-service-box" data-cue="zoomIn">
                        <div className="service-box-thumb">
                          <img src={data.photoServices2} alt="" />
                        </div>
                        <div className="service-content">
                          <h4>{data.servicesBox2_Title}</h4>
                          <p>{data.servicesBox2_Text}</p>
                        </div>
                      </div>
                    </Zoom>
                    {/* Service 03 */}
                    <Zoom
                      className="col-12 col-md-12 col-lg-4"
                      duration="3500"
                      delay="0"
                    >
                      <div className="single-service-box" data-cue="zoomIn">
                        <div className="service-box-thumb">
                          <img src={data.photoServices3} alt="" />
                        </div>
                        <div className="service-content">
                          <h4>{data.servicesBox3_Title}</h4>
                          <p>{data.servicesBox3_Text}</p>
                        </div>
                      </div>
                    </Zoom>
                    {/* Service 04 */}
                    <Zoom
                      className="col-12 col-md-12 col-lg-6"
                      duration="3500"
                      delay="0"
                    >
                      <div className="single-service-box" data-cue="zoomIn">
                        <div className="service-box-thumb">
                          <img src={data.photoServices4} alt="" />
                        </div>
                        <div className="service-content">
                          <h4>{data.servicesBox4_Title}</h4>
                          <p>{data.servicesBox4_Text}</p>
                        </div>
                      </div>
                    </Zoom>
                    {/* Service 05 */}
                    <Zoom
                      className="col-12 col-md-12 col-lg-6"
                      duration="3500"
                      delay="0"
                    >
                      <div className="single-service-box" data-cue="zoomIn">
                        <div className="service-box-thumb">
                          <img src={data.photoServices5} alt="" />
                        </div>
                        <div className="service-content">
                          <h4>{data.servicesBox5_Title}</h4>
                          <p>{data.servicesBox5_Text}</p>
                        </div>
                      </div>
                    </Zoom>
                  </div>
                </div>
              </div>
              {/*<div className="blog-area">
                <div className="container">
                  <Zoom duration="3500" delay="0">
                    {" "}
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="section-title center">
                          <div className="section-thumb">
                            <img
                              src="assets/images/home-1/section-shape1.png"
                              alt=""
                            />
                          </div>
                          <h1>Latest post from blog</h1>
                          <p className="section-desc-1">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting Lorem Ipsum is simply dummy text of the
                            printing and typesetting
                          </p>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                  <div className="row">
                    {blogPosts.map((post) => (
                      <Zoom
                        key={post.id}
                        className="col-lg-4 col-md-6"
                        duration="3500"
                        delay="0"
                        cascade
                      >
                        <div className="single-blog-box">
                          <div className="single-blog-thumb">
                            <img src={post.image} alt="" />
                          </div>
                          <div className="blog-content">
                            <div className="meta-blog">
                              <span>{post.date}</span>
                            </div>
                            <Link to={post.link}>{post.title}</Link>
                          </div>
                          <div className="blog-button">
                            <Link to={post.link}>
                              Read More{" "}
                              <span>
                                <i className="bi bi-arrow-right"></i>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </Zoom>
                    ))}
                  </div>
                </div>
              </div>*/}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Cruises;
