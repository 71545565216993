import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { fetchSightseeing } from "./../data/sightseeingSlice";
import Loader from "../Loader";
import "./dining/dining.css";
import { useMediaQuery } from "react-responsive";
import { BookingForm } from "../components/BookingForm";

const Sightseeing = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { sightseeing, loading, error } = useSelector(
    (state) => state.sightseeing
  );
  useEffect(() => {
    dispatch(fetchSightseeing(language));
  }, [dispatch]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <meta
                  name="description"
                  content={sightseeing.pageMetatagDescription}
                />
                <meta name="keywords" content={sightseeing.pageMetatagTitle} />

                <title>{sightseeing.pageTitle}</title>
              </Helmet>
              {/*banner */}

              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${
                      isMobile
                        ? sightseeing?.pageBannerMobile
                        : isTablet
                        ? sightseeing?.pageBannerTablet
                        : sightseeing?.pageBanner
                    })`,
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4>{sightseeing.pageTitle}</h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            {" "}
                            <ul>
                              <li>
                                <Link to="/" style={{ color: "#fff" }}>
                                  {language === "en" ? "Home" : "الرئيسية"}
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>{sightseeing.pageTitle}</li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*booking form */}

              {window.location.href.includes("agatha") ? "" : <BookingForm />}
              {/*FACILITIES list */}
              <section>
                <div className="facilities-area">
                  <div className="container">
                    <Zoom duration="2500" delay="0">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <div className="section-title center inner">
                            <div className="section-thumb">
                              <img
                                src="section-shape1.png"
                                alt="section shape icon"
                                loading="lazy"
                              />
                            </div>
                            <h1>{sightseeing.pageTitle}</h1>
                            <p
                              className="section-desc-1"
                              dangerouslySetInnerHTML={{
                                __html: `${sightseeing.pageDetails}`,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </Zoom>

                    {sightseeing.list_Sightseeing?.map((sightseeing, index) => (
                      <div className="row add-boder" key={index}>
                        <div className="col-lg-6 col-md-6">
                          <div
                            className="single-facilities-images-box"
                            data-cue="zoomIn"
                          >
                            <div className="facilities-thumb">
                              <img src={sightseeing.sightPhoto} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div
                            className="single-facilities-box"
                            data-cue="zoomIn"
                          >
                            <div className="facilities-content">
                              <h4>{sightseeing.pageTitle} </h4>
                              <h1>{sightseeing.sightName}</h1>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: `${sightseeing.sightNameDetails}`,
                                }}
                              />
                              {/* <Link
                                className="facilities-button"
                                to={sightseeing.sightURL}
                              >
                                <i className="bi bi-arrow-right"></i>
                              </Link> */}
                            </div>
                            <div className="facilities-number">
                              <h1>{index + 1}</h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Sightseeing;
