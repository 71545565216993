import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchItineraryDetails = createAsyncThunk(
  "itineraryDetails/fetchItineraryDetails",
  async ({ hotelUrl, itineraryURL }) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/GetItinerariesDetails/en/${hotelUrl}/${itineraryURL}`
    );
    const data = await response.json();
    console.log(data);
    return data;
  }
);

const itinerary = createSlice({
  name: "itineraryDetails",
  initialState: { itinerary: null, loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItineraryDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchItineraryDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.itinerary = action.payload;
      })
      .addCase(fetchItineraryDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default itinerary.reducer;
