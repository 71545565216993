import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { BookingForm } from "../components/BookingForm";

const BookingSearch = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Cabin Booking</title>
      </Helmet>
      {/*banner */}
      <section>
        <div
          className="breadcumb-area d-flex align-items-center"
          style={{
            background: `url(https://cms.nilecapitalcruises.com/images/uploads/be/banner-nile-capital-cruise.jpg)`,
          }}
        >
          <div className="container" style={{ visibility: "hidden" }}>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="breacumb-content">
                  <Zoom duration="2500">
                    {" "}
                    <div className="breadcum-title">
                      <h4>Cabins Booking</h4>
                    </div>
                  </Zoom>
                  <Fade duration="2500" direction="up">
                    {" "}
                    <ul>
                      <li>
                        <Link to="/" style={{ color: "#fff" }}>
                          Home
                        </Link>
                      </li>
                      <li className="px-2"> / </li>
                      <li>Cabins Booking</li>
                    </ul>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5 pb-5">
        <Zoom duration="2500" delay="0">
          <div className="row pb-5">
            <div className="col-md-12 text-center">
              <div className="section-title center inner">
                <div className="section-thumb">
                  <img
                    src="section-shape1.png"
                    alt="section shape icon"
                    loading="lazy"
                  />
                </div>
                <h1>Nile Cruise Booking</h1>
              </div>
            </div>
          </div>
        </Zoom>
        <BookingForm />
      </section>
    </>
  );
};

export default BookingSearch;
