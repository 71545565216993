import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { BookingForm } from "../../components/BookingForm";
import Loader from "../../Loader";
import { fetchItineraryDetails } from "./../../data/itineraryDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const ItinerariesDetails = () => {
  const language = useSelector((state) => state.language.language);
  const { itineraryURL } = useParams();
  const dispatch = useDispatch();
  const { itinerary, loading, error } = useSelector(
    (state) => state.itineraryDetails
  );

  // const htmlString = `${itinerary?.map((i) => i.itineraryDetails)}`;
  let hotelUrl = "";
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    hotelUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    hotelUrl = "nile-capital-cruise";
  }
  useEffect(() => {
    if (itineraryURL) {
      dispatch(fetchItineraryDetails({ hotelUrl, itineraryURL }));
    }
  }, [dispatch, hotelUrl, itineraryURL]);

  const [openFaqs, setOpenFaqs] = useState(0);

  const toggleFaq = (index) => {
    setOpenFaqs((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <meta
                  name="description"
                  content={itinerary?.map(
                    (itinerary) => itinerary.itineraryMetatagDescription
                  )}
                />
                <meta
                  name="keywords"
                  content={itinerary?.map(
                    (itinerary) => itinerary.itineraryMetatagTitle
                  )}
                />

                <title>
                  {itinerary?.map((itinerary) =>
                    itinerary.itineraryMetatagTitle
                      ? itinerary.itineraryMetatagTitle
                      : itinerary.itineraryName
                  )}
                </title>
              </Helmet>
              {/*banner */}
              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${
                      isMobile
                        ? itinerary?.map((i) => i.itineraryBanner_Mobile)
                        : isTablet
                        ? itinerary?.map((i) => i.itineraryBanner_Tablet)
                        : itinerary?.map((i) => i.itineraryBanner)
                    })`,
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4 style={{ fontFamily: "unset" }}>
                                {itinerary?.map(
                                  (itinerary) => itinerary.itineraryName
                                )}
                              </h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            {" "}
                            <ul>
                              <li>
                                <Link
                                  to={`/${hotelUrl}`}
                                  style={{ color: "#fff" }}
                                >
                                  {language === "en" ? "Home" : "الرئيسية"}
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>
                                <Link
                                  to={`/${hotelUrl}/itineraries`}
                                  style={{ color: "#fff" }}
                                >
                                  {language === "en"
                                    ? "Itineraries"
                                    : "المسارات"}
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>
                                {" "}
                                {itinerary?.map(
                                  (itinerary) => itinerary.itineraryName
                                )}{" "}
                              </li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {window.location.href.includes("agatha") ? "" : <BookingForm />}
              {/*FACILITIES list */}
              <section>
                <div className="facilities-area">
                  <div className="container">
                    <Zoom duration="2500" delay="0">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <div className="section-title center inner">
                            <div className="section-thumb">
                              <img src="section-shape1.png" alt="" />
                            </div>
                            <h1 style={{ fontFamily: "unset" }}>
                              {itinerary?.map(
                                (itinerary) => itinerary.itineraryName
                              )}
                            </h1>
                            <p
                              className="section-desc-1"
                              dangerouslySetInnerHTML={{
                                __html: `${itinerary?.map(
                                  (itinerary) => itinerary.itineraryIntro
                                )}`,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </Zoom>
                    <div className="row  flex-column-reverse flex-lg-row justify-content-around  align-items-center">
                      <div className="col-12 col-md-12 col-lg-6">
                        {itinerary?.map((itinerary) =>
                          itinerary.list_Itineraries_Details_Days.map(
                            (day, index) => (
                              <div
                                key={index}
                                className="faq-item col-12 pt-0 "
                                style={{ borderBottom: "1px solid #D9DFE7" }}
                              >
                                <div
                                  className={`question pt-3 pb-2 ${
                                    openFaqs[index] ? "active" : ""
                                  }`}
                                  onClick={() => toggleFaq(index)}
                                >
                                  {day.dayTitle} Day {day.dayNumber}
                                </div>
                                {openFaqs[index] && (
                                  <div
                                    style={{
                                      maxHeight: "1500px",
                                      minHeight: "250px",
                                    }}
                                    className="  answer"
                                  >
                                    <ol
                                      className={`${
                                        language === "en" ? "ps-0" : "pe-0"
                                      }`}
                                      style={{ listStyle: "outside" }}
                                    >
                                      <li className="d-flex justify-content-start align-items-baseline">
                                        <p
                                          className="fw-600 mb-0 py-0 ps-0 text-secondary"
                                          dangerouslySetInnerHTML={{
                                            __html: `${day.dayDetails}`,
                                          }}
                                        ></p>
                                      </li>
                                    </ol>
                                  </div>
                                )}
                              </div>
                            )
                          )
                        )}
                      </div>
                      <div className="col-12 col-md-12 col-lg-5">
                        <div
                          className="single-facilities-images-box"
                          data-cue="zoomIn"
                        >
                          <div className="facilities-thumb">
                            <img src="map.jpg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ItinerariesDetails;
