import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Loader from "../../Loader";

import { fetchThanksData } from "../../data/getThanksPageSlice";

const ThanksPage = () => {
  // const { cabins, selectedCabin } = useSelector((state) => state.cabins);
  const { thanksData, loadings, errors } = useSelector(
    (state) => state.thanksData
  );
  // const { thanksData?, loadings, errors } = useSelector(
  //   (state) => state.thanksData?
  // );
  // const storedData = localStorage.getItem("bookingFormData");

  // const itemObj = JSON.parse(storedData);
  // const itinerary_name = JSON.parse(localStorage.getItem("itinerary_name"));
  // const formattedDateString = itemObj.Str_Date.replace(/,/g, "-");
  // const dateObject = new Date(formattedDateString);
  // const displayDate = format(dateObject, "PP");
  const orderID = localStorage.getItem("orderID");
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchThanksData(orderID));
    }, [1000]);
  }, [dispatch, orderID]);

  return (
    <>
      {errors ? (
        <section className="mx-auto py-5 my-5">
          <h1 className="text-center text-danger"> {errors}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>Thank You</title>
          </Helmet>
          {/* form */}

          <section
            className="payment-area section-bg section-padding "
            style={{ background: "#0A254C" }}
          >
            <div className="container mt-5 pt-5">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-box payment-received-wrap mb-0">
                    <div className="form-title-wrap">
                      <div className="step-bar-wrap text-center ">
                        <ul className="step-bar-list d-flex align-items-center justify-content-around">
                          <li className="step-bar flex-grow-1 step-bar-active">
                            <span className="icon-element">1 </span>
                            <p className="pt-2 color-text-2">Choose Cruise </p>
                          </li>
                          <li className="step-bar flex-grow-1 step-bar-active">
                            <span className="icon-element">2 </span>
                            <p className="pt-2 color-text-2">Choose Cabin </p>
                          </li>
                          <li className="step-bar flex-grow-1 step-bar-active">
                            <span className="icon-element">3 </span>
                            <p className="pt-2 color-text-2">
                              Booking Completed!{" "}
                            </p>
                          </li>
                          <li className="step-bar flex-grow-1 step-bar-active">
                            <span className="icon-element">4 </span>
                            <p className="pt-2 color-text-2">Thank You </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="card-area section--padding my-5"
            id="booking-form-data"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-box booking-detail-form">
                    <div className="form-content">
                      <div className="card-item shadow-none radius-none mb-0">
                        <div className="card-body p-0">
                          <div className="d-flex justify-content-between">
                            <div className="mx-auto text-center d-block">
                              <p className="card-title fw-bold fs-4 mb-4  py-4">
                                Thank You {thanksData?.customerFirstName}
                              </p>
                            </div>
                          </div>

                          <p className="card-title pb-2 fw-bold fs-5">
                            Order Details:
                          </p>
                          <div className="section-block"></div>
                          <ul className="list-items list-items-2 py-3">
                            <li className="py-3 px-2 fs-5">
                              <span className="me-2 fw-bold">
                                {thanksData?.cruiseNameSys}
                              </span>
                            </li>
                            <li
                              className="py-3 px-2"
                              style={{ backgroundColor: "#f5f5f5" }}
                            >
                              <span className="me-2 fw-bold">
                                {thanksData?.itineraryNameSys}
                              </span>
                            </li>
                            <li className="font-size-15  py-3 px-2">
                              <span className="me-2 fw-bold">
                                <InsertInvitationIcon
                                  sx={{ color: "#000", mr: 1, width: 20 }}
                                />
                                Check In :
                              </span>
                              {thanksData?.str_OperationDate}
                            </li>
                            {/*<li
                          className="py-3 px-2  "
                          style={{ backgroundColor: "#f5f5f5" }}
                        >
                          <span className="me-2 fw-bold">
                            Payment Status:
                          </span>
                          {thanksData?.paymentStatus}
                        </li>
                        <li className="py-3 px-2  ">
                          <span className="me-2 fw-bold">
                            Order Confirmation Number:
                          </span>
                          {thanksData?.orderConfirmationNumber}
                        </li>
                        <li
                          className="py-3 px-2  "
                          style={{ backgroundColor: "#f5f5f5" }}
                        >
                          <span className="me-2 fw-bold">
                            Currency:
                          </span>
                          {thanksData?.currency}
                        </li>
                        <li className="py-3 px-2  ">
                          <span className="me-2 fw-bold">Card:</span>
                          {thanksData?.card}
                        </li>
                        <li
                          className="py-3 px-2  "
                          style={{ backgroundColor: "#f5f5f5" }}
                        >
                          <span className="me-2 fw-bold">
                            Order Reference:
                          </span>
                          {thanksData?.orderReference}
                        </li>
                        <li className="py-3 px-2  ">
                          <span className="me-2 fw-bold">
                            Card Brand:
                          </span>
                          {thanksData?.cardBrand}
                        </li> */}
                            <li
                              className="py-3 px-2"
                              style={{ backgroundColor: "#f5f5f5" }}
                            >
                              <span className="me-2 fw-bold">Cabin Name:</span>
                              {thanksData?.cabinNameSys}
                            </li>

                            <li className="py-3 px-2">
                              <span className="me-2 fw-bold">
                                Number Of Adults:{" "}
                              </span>
                              {thanksData?.adultsNo}
                            </li>
                            <li
                              className="py-3 px-2"
                              style={{ backgroundColor: "#f5f5f5" }}
                            >
                              <span className="me-2 fw-bold">
                                Number Of Child:
                              </span>
                              {thanksData?.childNo}
                            </li>
                          </ul>
                          <div className="section-block"></div>
                          <ul className="list-items list-items-2 list-items-flush py-2"></ul>

                          <p className="card-title text-center  pb-2 fw-bold fs-5">
                            Total Price:
                            <span
                              className="fw-bold ps-2"
                              style={{ color: "#D9B95E" }}
                            >
                              {thanksData?.orderTotalPrice}{" "}
                              {thanksData?.currency}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ThanksPage;
